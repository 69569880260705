import React from 'react';
import './Maps.css';
import argentina from '../../imagenes/argentina.png';

const Maps = () => {
    return (
        <section className='maps container'>
            <div className="container-row">
                <div className="container-colum-iframe">
                <iframe 
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d247.81775230020114!2d-70.718644198559!3d-33.31293398096587!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662c6804969831f%3A0xd6dd09ae1b92256b!2sBarron%20Vieyra%20International%20Spa.!5e0!3m2!1ses-419!2sar!4v1699900250429!5m2!1ses-419!2sar"
                    /* width="600" */
                    height="450"
                    style={{ border: 0 }}
                    loading="lazy"
                    /* referrerpolicy="no-referrer-when-downgrade" */
                ></iframe>
                </div>
                <div className="container-colum">
                    <div className="row">
                        <div className="col s12 boxInfo">
                            <div>
                                <ion-icon name="location-outline"></ion-icon>
                            </div>
                            <div>
                                <p><b>Dirección de Agencia Comercial:</b></p>
                                <p>Calle Barrón Vieyra 464, Comuna de Colina, Región Metropolitana, Santiago de Chile.</p>
                                <p><b>Dirección de Planta Industrial:</b></p>
                                <p>Calle Barrón Vieyra 464, Comuna de Colina, Región Metropolitana, Santiago de Chile.</p>
                                <p><a href='http://www.mecaelectromecanica.com.ar/'>
                                    <img className='bandera-navBar' alt='Bandera Argentina' src={argentina}></img>
                                </a><b>   Filial en Argentina</b></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Maps