const idPublicaciones =
    [
        {
            idPublicacion : '7175593233840816128',
        },
        {
            idPublicacion : '7164354004649472000',
        },
        {
            idPublicacion : '7156353310218760192',
        },
        {
            idPublicacion : '7125576832120205312',
        }
    ]

export const getIdPublicaciones = () => {
    return idPublicaciones;
}

/* https://www.linkedin.com/posts/meca-electromec%C3%A1nica-cl_meca-mecaelectromecanica-dartelelectricidad-activity-7175593233840816128-X8c7?utm_source=share&utm_medium=member_desktop

https://www.linkedin.com/posts/meca-electromec%C3%A1nica-cl_lifeison-ecoxpert-mecachile-activity-7164354004649472000-4_uH?utm_source=share&utm_medium=member_desktop

https://www.linkedin.com/posts/meca-electromec%C3%A1nica-cl_proyectonaviaxapoquindo-ingenieraedaelaezctrica-activity-7156353310218760192-TtJ5?utm_source=share&utm_medium=member_desktop

https://www.linkedin.com/posts/meca-electromec%C3%A1nica-cl_nuevaoficina-innovaciaejn-equipoincreaedble-activity-7125576832120205312-SMwO?utm_source=share&utm_medium=member_desktop */